<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<domains-input :domainNames.sync="queryForm.domains"></domains-input>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="addOne">新 增</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="downloadTask">下 载</el-button>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<el-table height="auto" :data="tableData">
				<el-table-column label="Domain" prop="domain" min-width="150">
				</el-table-column>
				<el-table-column label="Direction" prop="direction" min-width="150">
				</el-table-column>
				<el-table-column label="操作人" min-width="150">
					<template slot-scope="scope">
						<div>{{(scope.row.updatedBy || scope.row.createdBy).name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="添加日期" prop="createdAt" min-width="150">
					<template slot-scope="scope">
						<div>{{(scope.row.updatedAt || scope.row.createdAt) | formatDate('YYYY-MM-DD HH:mm:ss', false)}}
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
					<template slot-scope="scope">
						<el-button type="text" @click="editRow(scope.row)" size="mini">修改</el-button>
						<el-button type="text" class="text-danger" @click="delRow(scope.row)" size="mini">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
		<template v-if="form !== null">
			<el-dialog :title="form.id ? '修改': '新增'" :visible.sync="dialog.edit">
				<el-form ref="form" :model="form" label-width="80px" :rules="rules">
					<el-form-item label="Domain" prop="domain">
						<el-select v-model="form.domain" clearable size="mini" filterable class="w-100" :disabled="form.id!==undefined">
							<el-option v-for="domain, index in sortedDomainNames" :label="domain" :value="domain" :key="index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="Direction" prop="direction">
						<el-input v-model.trim="form.direction" size="mini"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialog.edit = false" size="mini">取 消</el-button>
					<el-button type="primary" @click="onSubmit" size="mini">确 定</el-button>
				</div>
			</el-dialog>
		</template>
		<template v-if="form !== null">
			<el-dialog title="删除" :visible.sync="dialog.del">
				<div>您正在执行危险操作，继续请按确定</div>
				<div slot="footer" class="dialog-footer">
					<el-button @click="dialog.del=false" size="mini">取 消</el-button>
					<el-button type="primary" size="mini" @click="delDomainDirection">确 定</el-button>
				</div>
			</el-dialog>
		</template>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import DomainsInput from '@/components/jscx/domains-input.vue'
	import {
		mapActions,
		mapState
	} from 'vuex'
	export default {
		mixins: [page, common],
		inject: ['adminLayout'],
		components: {
			DomainsInput
		},
		data() {
			return {
				namespace: 'jscx',
				queryForm: {
					domains: []
				},
				q: {},
				tableData: [],
				form: null,
				dialog: {
					edit: false,
					del: false,
					domainName: false
				},
				resources: {
					domainNames: []
				},
				rules: {
					domain: [{
						validator: (rule, value, callback) => {
							const domainPattern =
								/^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,}$/; // Adjust this regex as needed
							if (!value) {
								callback(new Error('Domain is required'));
							} else if (!domainPattern.test(value)) {
								callback(new Error('Invalid domain format'));
							} else {
								callback();
							}
						},
						trigger: 'blur'
					}],
					direction: [{
						validator: (rule, value, callback) => {
							if (!value) {
								callback(new Error('Direction is required'));
							} else {
								callback();
							}
						},
						message: 'Direction is required',
						trigger: 'blur'
					}]
				}
			}
		},
		computed: {
			sortedDomainNames() {
				const existedDomain = this.form && this.form.domain
				const domainNames = this.resources.domainNames.map(v => v.domainName)
				if (existedDomain && domainNames.includes(existedDomain) === false) {
					return [...domainNames, existedDomain].sort((a, b) => a.localeCompare(b))
				}
				return domainNames.sort((a, b) => a.localeCompare(b))
			}
		},
		created() {
			this.initResouces()
		},
		methods: {
			initResouces() {
				this.resources.domainNames = []
				const maxRetries = 3;
				// 封装重试逻辑的函数
				const fetchWithRetry = (action, resourceKey, retries) => {
					this.$store.dispatch(`${this.namespace}/${action}`, {hasDirection: false})
						.then(res => {
							this.resources[resourceKey] = res; // 成功时设置资源
						})
						.catch(() => {
							if (retries > 0) {
								console.warn(`重试 ${resourceKey}，剩余重试次数: ${retries}`);
								fetchWithRetry(action, resourceKey, retries - 1); // 失败时递归重试
							} else {
								console.error(`${resourceKey} 获取失败，已达到最大重试次数`);
							}
						});
				};
				// 调用重试方法获取资源
				fetchWithRetry('getDomains', 'domainNames', maxRetries);
			},
			...mapActions('jscx', [
				'fetchJSCXDomainDirections',
				'addJSCXDomainDirection',
				'updateJSCXDomainDirection',
				'delJSCXDomainDirection',
				'getDomains'
			]),
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchJSCXDomainDirections({
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			buildQueryForm(queryForm) {
				let query = {};
				for (let key in queryForm) {
					if (queryForm.hasOwnProperty(key) && queryForm[key].length > 0) {
						if (key === 'date') {
							const [startDate, endDate] = queryForm[key]
							query['startDate'] = startDate
							query['endDate'] = endDate
						} 
						else {
							query[key] = queryForm[key];
						}
					}
				}
				return query;
			},
			// Button Events
			searchEvent() {
				this.q = this.buildQueryForm(this.queryForm)
				this.page.current = 1
				this.getList()
			},
			downloadTask() {
				const q = this.buildQueryForm(this.queryForm)
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/fetchJSCXDomainDirections`,
					payload: {
						...q
					},
					title: `JSCX-Domain-Direction(${q.startDate}~${q.endDate})`,
					pagable: {page: 1, size: 50},
					dispatch: this.$store.dispatch,
					requestHandler() {
						const params = Array.from(arguments).reduce((lhs, rhs) => {
							return {...lhs, ...rhs}
						}, {})
						return {params}
					}
				})
			},
			addOne() {
				this.form = {
					domain: '',
					direction: ''
				}
				this.dialog.edit = true
			},
			onSubmit() {
				this.$refs['form'].validate((valid) => {
					if (valid) {
						this.dialog.edit = false
						this.adminLayout.showLoading()
						const payload = this.form.id ? {
							id: this.form.id,
							body: this.form
						} : this.form
						const doAction = this.form.id ? this.updateJSCXDomainDirection : this
							.addJSCXDomainDirection
						doAction(payload)
							.then(res => {
								this.adminLayout.hideLoading()
								this.$showSuccess(res)
								this.getList()
								this.initResouces()
							})
							.catch(error => {
								this.adminLayout.hideLoading()
								this.$showErrMsg(error)
							})
					} else {

						return false;
					}
				});
			},
			delDomainDirection() {
				this.dialog.del = false
				this.adminLayout.showLoading()
				this.delJSCXDomainDirection(this.form.id)
				.then(res => {
					this.adminLayout.hideLoading()
					this.getList()
					this.initResouces()
				})
				.catch(error => {
					this.adminLayout.hideLoading()
					this.$showErrMsg(error)
				})
			},
			editRow(row) {
				this.form = {
					...row
				}
				this.dialog.edit = true
			},
			delRow(row) {
				this.form = {...row}
				this.dialog.del = true
			}
		}
	}
</script>

<style lang="scss">
</style>